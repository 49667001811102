import { Component, OnInit, SimpleChanges } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Spinner } from "ngx-spinner/lib/ngx-spinner.enum";
import { ToastrService } from "ngx-toastr";
import { BillingService } from "../billing/billing.service";

// Before the component
declare var Stripe: any;

@Component({
  selector: "app-stripe",
  templateUrl: "stripe.component.html",
  styleUrls: ["stripe.component.css"],
})
export class StripeComponent implements OnInit {
  token;
  secret;
  sub_type;
  amount;

  constructor(private billing: BillingService, private spinner:NgxSpinnerService,private toastr: ToastrService,) {}

  ngOnInit() {
    this.spinner.show()
    this.secret = localStorage.getItem('client_secret')
    this.token = localStorage.getItem('publishable_key')
    this.sub_type = localStorage.getItem("sub_type");
    this.amount = localStorage.getItem("amount");

    // this.billing.clint_token.subscribe((res) => {
    //   this.token = res;

    //   console.log(this.token, "publisable key");
    // });
    // this.billing.clinet_secret.subscribe((res) => {
    //   this.secret = res;
    //   console.log(this.secret, "secret");
    // });
    // console.log(this.token, "in side");
    const stripe = Stripe(this.token);
    const options = {
      clientSecret: this.secret,
      // Fully customizable with appearance API.
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: false,
        spacedAccordionItems: true
      },
      appearance: {
        /*...*/
      },
    };
    // console.log("options", options);

    // Set up Stripe.js and Elements using the SetupIntent's client secret
    const elements = stripe.elements(options);
    // console.log("element", elements);

    // Create and mount the Payment Element
    const paymentElement = elements.create("payment", {
      paymentMethodOrder: ['card','ideal','bancontact',]
    });
    // console.log("payment element", paymentElement);
    paymentElement.mount("#payment-element");

    const form = document.getElementById("payment-form");
   setTimeout(() => {
    this.spinner.hide()
   }, 2000);
    form.addEventListener("submit", async (event) => {
      event.preventDefault();

      const response = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          // return_url: "http://localhost:4200/billing/subscription",
          // return_url: "https://app-dev.triggify.io/billing/subscription",
          return_url: "https://app.triggify.io/billing/subscription",
        },
      });
      this.toastr.error(response.error.message, "", {
        closeButton: true,
        positionClass: "toast-bottom-center",
      });
      console.log("response msg", response);
    });
  }
}
