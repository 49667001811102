import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ConfirmedValidator } from "../../shared/password-validator/confirmed.validator";
import { AuthService } from "../auth.service";

@Component({
    selector: 'app-forgot-password',
    templateUrl: 'forgot-password.component.html',
    styleUrls: ['forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {

    passwordResetPage: boolean = true
    resetConfirmForm;
    button_spinner = false

    passwordresetForm = new FormGroup({
        email: new FormControl("", [Validators.required,Validators.email])
    })

    

    constructor(private fb:FormBuilder,private spinner:NgxSpinnerService,private auth: AuthService, private toastr: ToastrService, private router: Router) { }


    ngOnInit(): void {
        this.resetConfirmForm = this.fb.group({
            otp: ['', Validators.required],
            // email: ['', Validators.required],
            new_password1:['',[ Validators.required,Validators.minLength(6)]],
            new_password2: [''],
        },
        {
            validator: ConfirmedValidator('new_password1', 'new_password2')
        }
            
        );
    }
    passwordresetRequest() {
        // this.spinner.show()
        // console.log(this.passwordresetForm.value);
        this.button_spinner = true
        this.auth.passwordReset(this.passwordresetForm.value.email).subscribe((res) => {
            this.button_spinner = false
            // console.log(res, "resxfgfg");
            if (res.status == 200) {
                this.passwordResetPage = false;
                this.toastr.success("Password reset OTP sent successfully", "", {
                    closeButton: false,
                    positionClass: 'toast-bottom-center'
                })
            } else {
                this.toastr.error(res.error.message, "", {
                    closeButton: false,
                    positionClass: 'toast-bottom-center',

                })
            }

        })
    }

    get f() {
        return this.resetConfirmForm.controls;
    }

    otpSubmit() {
        this.button_spinner = true
        this.auth.passwordresetotp(this.resetConfirmForm.value,this.passwordresetForm.value.email).subscribe((res) => {
            // console.log(res);
            this.button_spinner = false
            if (res.status == 400) {
                this.toastr.error(res.error.message, "", {
                    closeButton: false,
                    positionClass: 'toast-bottom-center',

                })
            } else {
                this.toastr.success("Password reset successfully!", "", {
                    closeButton: false,
                    positionClass: 'toast-bottom-center'
                })
                this.router.navigate([''])
            }

        })
    }



}