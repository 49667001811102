import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { BillingComponent } from './pages/billing/billing.component';
import { PlanSelectionComponent } from './pages/stepper-plan/planselection.component';
import { StripeComponent } from './pages/stripe/stripe.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { AgencyComponent } from './pages/agency/agency.component';
import { AgencyGuard } from './shared/guard/agency.guard';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'sign-up',
    component: SignUpComponent
  },
  {
    path: 'plan',
    component: PlanSelectionComponent
  },
  {
    path: 'agency-plan',
    component: AgencyComponent, canActivate: [AgencyGuard]
  },
  { path: 'stripe/add-card',  component:StripeComponent  },
  { path: "billing/subscription", component: SubscriptionComponent },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  }, 
  {
    path: 'login',
    redirectTo: 'login',
    pathMatch: 'full',
  }, 
  {
    path: '',
    // canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
      }]
  }
  ,
  {
    path: '**',
    redirectTo: 'posts'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: false })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
