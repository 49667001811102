/// <reference types="gapi.auth2" />
import { Injectable,NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { LocalService } from '../../shared/service/local.service';
import { TriggifyService } from '../../shared/service/triggify.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService implements OnInit {

    private auth: gapi.auth2.GoogleAuth;
    private user: gapi.auth2.GoogleUser;

    constructor(private zone: NgZone,private toastr: ToastrService,private locker: TriggifyService, private local: LocalService, private router: Router,private spinner: NgxSpinnerService) { }

    ngOnInit(): void {
        this.load()
        .then(() => this.init())
        .then(auth => {
          this.auth = auth;
        });
    }

    private load(): Promise<void> {
        return new Promise((resolve, reject) => {
          this.zone.run(() => {
            gapi.load('auth2', {
              callback: resolve,
              onerror: reject,
            });
          });
        });
      }
      private init(): Promise<gapi.auth2.GoogleAuth> {
        return new Promise((resolve, reject) => {
          this.zone.run(() => {
            const auth = gapi.auth2.init({
              client_id: '427964124465-lee6i48l5pphhovmorqk318bbppehgmi.apps.googleusercontent.com',
              scope: 'profile email',
            });
            resolve(auth);
          });
        });
      }
      loginForUser(): Promise<gapi.auth2.GoogleUser> {
        return this.zone.run(() => {
          return this.auth.signIn()
            .then(user => {
              this.user = user;
              return user;
            });
        });
      }
      loginForCode(): Promise<{ code: string }> {
        return this.zone.run(() => {
          return this.auth.grantOfflineAccess()
            .then(response => {
              return response;
            });
        });
      }
    doLogin(formdata) {
        this.spinner.show()
        let requestData = {
            email: formdata.email,
            password: formdata.password
        }
        return new Observable<any>((observe) => {
            this.locker.sendRequest('POST', '/api/account/login', requestData, null, null, null, false).subscribe(response => {
                // console.log(response);
                if (response.status == 400 || response.status == 401) {
                    this.spinner.hide()
                    // this.toastr.error("Login ID and password is not correct","",{
                    //     closeButton:true,
                    //     positionClass:'toast-top-left'
                    // })
                    // alert("username and password is incorrect")
                    // document.getElementById("login_error").style.display = "block";
                }
                if (response.status == 200) {
                    this.toastr.success("Login Successfully!! Welcome to Shot Locker Admin Panel","",{
                        closeButton:false,
                        positionClass:'toast-top-left'
                    })
                    this.spinner.hide()
                    this.router.navigate(['user']);
                    // alert("Login Sucessfully");


                } else {
                    this.spinner.hide()
                    this.toastr.error("Email ID and Password is not correct","",{
                        closeButton:false,
                        positionClass:'toast-top-left'
                    })
                    // alert("username and password is incorrect")
                }

                this.local.storeAccessToken(response.body['access_token']);
                this.local.setlocalstorage("islogin", true)
                observe.next(response);
                //   this.router.navigate(['dashboard'])
                //  alert("Login Sucessfully")
            })
        })

    }
}
