import { Injectable, OnInit } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { BillingService } from "../../pages/billing/billing.service";
import { AuthService } from "../../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class AgencyGuard implements CanActivate, OnInit {
  token;

  constructor(
    private billing: BillingService,
    private authService: AuthService,
    private roter: Router
  ) {}

  ngOnInit(): void {
    this.token = localStorage.getItem("");
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    var check = this.billing.isAgency();
    if (check) {
      return true;
    } else {
      this.roter.navigate(["billing"]);
      return false;
    }
  }
}
