import { Injectable } from "@angular/core";
import * as mixpanel from "mixpanel-browser";

@Injectable({
  providedIn: "root",
})
export class MixpanelService {
  userId;

  constructor() {
    // this.userId = localStorage.getItem("userId");
    // console.log(this.userId);
    // this.init();
  }

  // init(): void {
  //   mixpanel.init("65b00f14201ea910a6cb9e6ff2eb7083");
  //   mixpanel.identify(this.userId);
  // }

  /**
   * Push new action to mixpanel.
   *
   * @param id Name of the action to track.
   * @param [action={}] Actions object with custom properties.
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
}
