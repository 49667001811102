// shared.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LinkedinProfileComponent } from './app-linkedin-profile.component';

@NgModule({
  declarations: [
    LinkedinProfileComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    LinkedinProfileComponent
  ]
})
export class SharedModule { }
