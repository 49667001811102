import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TriggifyService } from "../shared/service/triggify.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private triggify: TriggifyService) {}

  signUp(signUpdata) {
    let bodyData = {
      name: signUpdata.f_name + " " + signUpdata.l_name,
      email: signUpdata.email.toLowerCase(),
      password: signUpdata.password,
      referral_used: signUpdata.referral_used,
    };
    // console.log(bodyData,"signup bodydata");

    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/signup",
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }

  // verify otp

  otpsubmission(otp, email) {

    let newData = {
        otp:otp,
        email: email.toLowerCase(),
      };
  
      const queryString = Object.keys(newData)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(newData[key])}`
        )
        .join("&");

    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/verify-otp?${queryString}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((response) => {
          Observe.next(response);
        });
    });
  }

  // Do Login//

  login(logindata) {
    let bodyData = {
      email: logindata.email.toLowerCase(),
      password: logindata.password,
    };
    // console.log(bodyData,"login bodyData");

    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/login",
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }

  // reset password

  passwordReset(emailId) {
    let bodyData = {
      email: emailId.toLowerCase(),
    };
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/password-reset",
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }

  passwordresetotp(newpassword, emailId) {
    let bodydata = {
      email: emailId.toLowerCase(),
      otp: newpassword.otp,
      new_password1: newpassword.new_password1,
      new_password2: newpassword.new_password2,
    };

    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/password-reset/confirm",
          bodydata,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }

  // Send mail email-id

  sendEmail(emailId) {
    // console.log(emailId,"email id");

    let formData = new FormData();
    formData.append("email", emailId.toLowerCase());
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/create/email",
          formData,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }

  isLogin() {
    return localStorage.getItem("token") !== null;
  }

  getOTPforverification(emailId) {
    let newData = {
      email: emailId.toLowerCase(),
    };

    const queryString = Object.keys(newData)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(newData[key])}`
      )
      .join("&");
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/get-otp?${queryString}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }

  verifyUserEMailid(emailId, otp) {

    let newData = {
        otp:otp,
        email: emailId.toLowerCase(),
      };
  
      const queryString = Object.keys(newData)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(newData[key])}`
        )
        .join("&");

        

    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "GET",
          `/api/account/verify-otp-login?${queryString}`,
          null,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }

  // sign in with google

  signInwithGoogle(token) {
    let bodyData = {
      access_token: token,
    };
    return new Observable<any>((Observe) => {
      this.triggify
        .sendRequest(
          "POST",
          "/api/account/google-login",
          bodyData,
          null,
          null,
          null,
          false
        )
        .subscribe((res) => {
          Observe.next(res);
        });
    });
  }
}
