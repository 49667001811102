import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { TriggifyService } from "../../shared/service/triggify.service";

@Injectable({
    providedIn:'root'
})
export class BillingService {

    sub;
    // public isLogin = new Subject<boolean>();
     clint_token = new Subject<any>();
     clinet_secret = new Subject<any>();
    stripeCode = new Subject<string[]>();
    stripeCode$ = this.stripeCode.asObservable();

    constructor(private triggify:TriggifyService){}

    

    cardAddedorNot(){
        return new Observable<any>((Observe)=>{
            this.triggify.sendRequest("GET",'/api/subscription/check-payment-method',null,null,null,null,false).subscribe((response)=>{
                Observe.next(response)
            })
        })
    }

    // On adding Card details

    onAddcard(){
        return new Observable<any>((Observe)=>{
            this.triggify.sendRequest("GET",'/api/subscription/create-payment-method',null,null,null,null,false).subscribe((response)=>{
                Observe.next(response)
            })
        })
    }

    // On create bill

    onCreateBill(){
        let bodyData = new FormData()
        bodyData.append('phone','123');
        bodyData.append('line1','abcd');
        bodyData.append('line2','654');
        bodyData.append('city','ranchi');
        bodyData.append('state','ranchi');
        bodyData.append('postal_code','123456');
        bodyData.append('country','India');
        
        return new Observable<any>((Observe)=>{
            this.triggify.sendRequest("POST",'/api/account/add-billing-details',bodyData,null,null,null,false).subscribe((response)=>{
                Observe.next(response)
            })
        })
    }

    currentPlan(){
        return new Observable<any>((Observe)=>{
            this.triggify.sendRequest("GET",'/api/subscription/check-user',null,null,null,null,false).subscribe((response)=>{
                Observe.next(response)
            })
        })
    }


    oncreate(sub_type,plan_type,plan_interval){
        let bodyData = new FormData()
        bodyData.append('plan_name',sub_type)
        bodyData.append('plan_type',plan_type);
        bodyData.append('plan_interval',plan_interval);
        
        return new Observable<any>((Observe)=>{
            this.triggify.sendRequest("POST",'/api/subscription/create-subscription',bodyData,null,null,null,false).subscribe((response)=>{
                Observe.next(response)
            })
        })
    }

    onUpgrade(sub_type,plan_type,plan_interval){
        let bodyData = new FormData()
        bodyData.append('plan_name',sub_type)
        bodyData.append('plan_type',plan_type)
        bodyData.append('plan_interval',plan_interval)
        
        return new Observable<any>((Observe)=>{
            this.triggify.sendRequest("POST",'/api/subscription/upgrade',bodyData,null,null,null,false).subscribe((response)=>{
                Observe.next(response)
            })
        })
    }

    onCancle(){
        return new Observable<any>((Observe)=>{
            this.triggify.sendRequest("GET",'/api/subscription/cancel',null,null,null,null,false).subscribe((response)=>{
                Observe.next(response)
            })
        })
    }

    retrivebillingDetails(){
        return new Observable<any>((Observe)=>{
            this.triggify.sendRequest("GET",'/api/account/update-delete/billing-details',null,null,null,null,false).subscribe((response)=>{
                Observe.next(response)
            })
        })
    }

    isSubscribe():boolean {
     return localStorage.getItem("susbcribe") === "True"
       
  
    }
    isAgency():boolean {
     return localStorage.getItem("agency") === "True"
       
  
    }
    isNotAgency():boolean {
     return localStorage.getItem("agency") === "false"
       
  
    }

    deleteBillingDetail(){
        return new Observable<any>((Observe)=>{
            this.triggify.sendRequest("DELETE",'/api/account/update-delete/billing-details',null,null,null,null,false).subscribe((response)=>{
                Observe.next(response)
            })
        })
    }

    changePlan(){
        return new Observable<any>((Observe)=>{
            this.triggify.sendRequest("GET",'/api/subscription/plan-change-send-email',null,null,null,null,false).subscribe((response)=>{
                Observe.next(response)
            })
        })
    }
}