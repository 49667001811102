import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function noUrlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const urlPattern = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    if (control.value && urlPattern.test(control.value)) {
      return { urlNotAllowed: true };
    }

    return null;
  };
}
