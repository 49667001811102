import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BillingService } from "../../pages/billing/billing.service";
import { AgencyService } from "../../pages/agency/agency.service";
import { CommonService } from "../../pages/common.service";
import { TriggerService } from "../../pages/trigger/trigger.service";
import { LocalService } from "../../shared/service/local.service";
import { element } from "protractor";
declare let analytics: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: "/user", title: "Users", icon: "users_single-02", class: "" },
  { path: "/range", title: "Range", icon: "location_world", class: "" },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  lists = [];

  isSub;
  toggle = false;
  showorhideModal = false;
  totalCount = 0;
  allPostCount = 0;
  closeInterval;
  profileInfo = {};
  profileDetailsAvailable = false;
  linkdinProfileArray = [];
  bussinessProfileArray = [];
  sub_type;
  profileAdded = true;
  modalHeader = "Select trigger type";
  linkdin_Id;
  agencyPlan = false;
  selectOptionList = [];
  filterActorUrn = [];
  actor_urn;

  // variable for analytics

  triggerCount;
  postCount;
  likeCount;
  linkedin_con_status;

  constructor(
    private common: CommonService,
    private triggerr: TriggerService,
    private router: Router,
    private toastr: ToastrService,
    private local: LocalService,
    private agency: AgencyService,
    private billing: BillingService
  ) {}

  toggleArrow() {
    this.toggle = !this.toggle;
  }

  linkedInProfileForm = new FormGroup({
    select_profile: new FormControl("", Validators.required),
  });

  ngOnInit() {
    this.linkdin_Id = localStorage.getItem("linkedinId");
    this.getLinkdinCredList();
    this.getCurrentPlan();
    this.triggerCount = localStorage.getItem("triggerCount");
    this.postCount = localStorage.getItem("postCount");
    this.likeCount = localStorage.getItem("likeCount");
    this.linkedin_con_status = localStorage.getItem("linkedin_con_status");
    this.sub_type = localStorage.getItem("sub_type");
    this.triggerr.showorhideModal.subscribe((res) => {
      this.linkdin_Id = localStorage.getItem("linkedinId");
      this.showorhideModal = res;
      this.getallTriggerList();
      // this.totalpostCount();
    });
    this.triggerr.linkedinConnectionStatus.subscribe((res) => {
      this.linkdin_Id = localStorage.getItem("linkedinId");
      this.gerLinkdinConnectionStatus();
    });

    this.triggerr.modalHeader.subscribe((res) => {
      this.linkdin_Id = localStorage.getItem("linkedinId");
      this.modalHeader = res;
    });
  }

  totalpostCount() {
    this.common
      .getlinkedinPost("view_all", this.linkdin_Id)
      .subscribe((res) => {
        this.postCount = localStorage.getItem("postCount");
        this.likeCount = localStorage.getItem("likeCount");
        // console.log(res,"post for count");
        this.allPostCount = res.body.total_posts;
        if (res.body.total_posts != this.postCount) {
          analytics.track("Number of matching posts per user", {
            count: res.body.total_posts,
          });
          localStorage.setItem("postCount", res.body.total_posts);
        }
        if (res.body.total_likes != this.likeCount) {
          analytics.track("Number of liked posts per user", {
            count: res.body.total_likes,
          });
          localStorage.setItem("likeCount", res.body.total_likes);
        }
      });
  }

  trigger(value) {
    this.local.triggerName.next(value.trigger_name);
    this.local.triggerId.next(value.id);
    this.local.triggerCount.next(value.count);
    localStorage.setItem("count", value.count);
    localStorage.setItem("id", value.id);
    localStorage.setItem("trigger_Name", value.trigger_name);
    // localStorage.setItem('edit_trugger','true')
    // this.router.navigate(['home', value]);
  }

  triggerall(value, count) {
    this.local.triggerName.next(value);
    localStorage.setItem("count", count);
    localStorage.setItem("trigger_Name", value);
    localStorage.setItem("edit_trugger", "false");
    this.local.triggerCount.next(count);
    localStorage.setItem("allpostcount", count);
  }

  ngDoCheck(): void {
    this.isSub = localStorage.getItem("susbcribe");
  }

  logOut() {
    this.router.navigate([""]);
    localStorage.clear();
    this.toastr.success("user logout.", "", {
      closeButton: false,
      positionClass: "toast-bottom-center",
    });
  }

  showpopup() {
    this.triggerr.showorhideModal.next(true);
    document.getElementById("z-navbar").style.zIndex = "1";
    document.getElementById("z-sidebar").style.zIndex = "10";
  }

  hidepopup() {
    this.triggerr.showorhideModal.next(false);
  }
  getallTriggerList() {
    this.common.getKeywords(this.linkdin_Id).subscribe((res) => {
      this.triggerCount = localStorage.getItem("triggerCount");
      this.lists = res.body;
      if (res.body.length != this.triggerCount) {
        analytics.track("Number of triggers per user", {
          count: res.body.length,
        });

        localStorage.setItem("triggerCount", res.body.length);
      }
      if (res.body.length) {
        localStorage.setItem("1st_trigger", "false");
      } else {
        localStorage.setItem("1st_trigger", "true");
      }
      this.totalCount = 0;
      for (let index = 0; index < res.body.length; index++) {
        this.totalCount += res.body[index].count;
      }
      if (this.totalCount > 0) {
        clearInterval(this.closeInterval);
      }
    });
  }

  gerLinkdinConnectionStatus() {
    // this.spinner.show()
    this.common.getLinkedinProfile(this.linkdin_Id).subscribe((res) => {
      if (res.status == 200) {
        this.profileInfo = res.body[0];
        //  this.profileInfo['cookie']=null
        this.profileDetailsAvailable = true;

        this.linkedin_con_status = localStorage.getItem("linkedin_con_status");
        if (res.body[0].cookie?.toString() != this.linkedin_con_status) {
          if (res.body.cookie) {
            analytics.track("Has LinkedIn account connected", {});
          } else {
            analytics.track("Has LinkedIn account disconnected", {});
          }
          localStorage.setItem("linkedin_con_status", res.body.cookie);
        }
        // console.log(this.profileInfo,"profile info");
      } else {
        if ("error" != this.linkedin_con_status) {
          analytics.track("Has LinkedIn account disconnected", {});
          localStorage.setItem("linkedin_con_status", "error");
        }
      }
    });
  }

  getLinkedinProfile() {
    // console.log(this.sub_type, "sub type");

    this.common.getSelectProfileOption(this.linkdin_Id).subscribe((res) => {
      if (res.status == 200) {
        this.linkdinProfileArray = res.body;
        this.common.getLinkDinProfile(this.linkdin_Id).subscribe((res) => {
          // console.log(res, "profile response");
          if (res.status == 200) {
            this.profileAdded = true;

            if (
              res.body.profile.length == 0 ||
              res.body.profile.length == undefined ||
              res.body.profile.length == null ||
              res.body.profile.length == "null"
            ) {
              this.profileAdded = false;
              this.profileSelectArrayLogic();
            }
          } else {
            this.profileAdded = false;
            this.profileSelectArrayLogic();
            // this.linkdinProfileArray = res.body;
          }
        });
      } else {
        this.profileAdded = true;
      }
    });
  }

  profileSelectArrayLogic() {
    for (let index = 0; index < this.linkdinProfileArray.length; index++) {
      const name = this.linkdinProfileArray[index].name.replace(
        "_companyprofile_",
        ""
      );
      this.linkdinProfileArray[index].select = name;
    }

    if (this.linkdinProfileArray.length > 1) {
      this.bussinessProfileArray = [this.linkdinProfileArray.shift()];
    } else {
      this.bussinessProfileArray = this.linkdinProfileArray;
    }
    if (this.linkdinProfileArray.length > 0) {
      if (this.sub_type == "Business" || this.sub_type.includes("Agency")) {
        // this.showorhideProfileModal = true;
        this.showorhideModal = true;
        this.linkedInProfileForm.setValue({
          select_profile: this.linkdinProfileArray[0].name,
        });
        // this.selectLinkdinProfile();
      } else {
        this.linkedInProfileForm.setValue({
          select_profile: this.bussinessProfileArray[0].name,
        });
        this.selectLinkdinProfile();
      }
    }
  }

  linkdinProfiledelete() {
    this.common.getLinkDinProfile(this.linkdin_Id).subscribe((res) => {
      // console.log(res,"profile response");
      if (res.status == 200) {
        this.common.deleteLinkDinProfile(this.linkdin_Id).subscribe((res) => {
          // console.log(res,"delete response");
        });
      } else {
        // console.log("not Added");
      }
    });
  }

  selectLinkdinProfile() {
    if (this.showorhideModal) {
      let data = this.linkdinProfileArray.filter((profile) =>
        profile.name.includes(this.linkedInProfileForm.value.select_profile)
      );

      this.actor_urn = data[0]["actor_urn"];
    } else {
      this.actor_urn = null;
    }

    this.common.getLinkDinProfile(this.linkdin_Id).subscribe((res) => {
      // console.log(res,"profile response");
      if (res.status == 200) {
        this.common.deleteLinkDinProfile(this.linkdin_Id).subscribe((res) => {
          // console.log(res,"delete response");
          this.common
            .addLinkedinProfile(
              this.linkedInProfileForm.value.select_profile,
              this.linkdin_Id,
              this.actor_urn
            )
            .subscribe((res) => {
              // console.log(res);
              if (res.status == 200) {
                this.hidepopup();
                this.profileAdded = true;
                this.triggerr.linkedinProfileShowHide.next(false);
                // this.common.updateTriggerProfile().subscribe((res) => {
                //   // console.log(res, "trigger profile updated");

                // });
              }
            });
        });
      } else {
        this.common
          .addLinkedinProfile(
            this.linkedInProfileForm.value.select_profile,
            this.linkdin_Id,
            this.actor_urn
          )
          .subscribe((res) => {
            // console.log(res);
            if (res.status == 200) {
              this.hidepopup();
              this.profileAdded = true;
              this.triggerr.linkedinProfileShowHide.next(false);
            }
          });
      }
    });
  }

  getLinkdinCredList() {
    this.agency.llinkedincredList().subscribe((res) => {
      this.selectOptionList = res.body;
      this.linkdin_Id = Number(this.linkdin_Id);

      if (this.linkdin_Id == null || this.linkdin_Id == 0) {
        this.linkedInProfileForm.patchValue({
          select_profile: res.body[0].id,
        });
        localStorage.setItem("linkedinId", res.body[0].id);
        this.getallTriggerList();
        this.totalpostCount();
        this.gerLinkdinConnectionStatus();
        this.getLinkedinProfile();
      } else {
        this.linkedInProfileForm.patchValue({
          select_profile: this.linkdin_Id,
        });
        localStorage.setItem("linkedinId", this.linkdin_Id);
        this.getallTriggerList();
        this.totalpostCount();
        this.gerLinkdinConnectionStatus();
        this.getLinkedinProfile();
      }
    });
  }

  selectedLinkedinProfile(event) {
    this.linkdin_Id = event;
    localStorage.setItem("linkedinId", event);
    this.getallTriggerList();
    this.totalpostCount();
    this.gerLinkdinConnectionStatus();
    this.getLinkedinProfile();
    this.triggerr.linkedinProfileShowHide.next(false);
    this.router.navigate(["agency-dashbaord"]);
  }

  getCurrentPlan() {
    this.billing.currentPlan().subscribe((response) => {
      if (response.body.message == "No subscription found") {
        this.toastr.error(
          "Your subscription has ended. Please select a plan to continue.",
          "",
          {
            closeButton: false,
            positionClass: "toast-bottom-center",
          }
        );
        localStorage.removeItem("sub_type");
        localStorage.removeItem("amount");
        localStorage.removeItem("plan_type");
        localStorage.removeItem("plan_interval");
        localStorage.removeItem("headerHeading");
        localStorage.removeItem("from_page");
        localStorage.removeItem("first_step");
        this.router.navigate(["plan"]);

        this.agencyPlan = false;
      } else if (response?.body?.message?.payment_status == "payment failed") {
        localStorage.removeItem("sub_type");
        localStorage.removeItem("amount");
        localStorage.removeItem("plan_type");
        localStorage.removeItem("plan_interval");
        localStorage.removeItem("headerHeading");
        localStorage.removeItem("from_page");
        localStorage.removeItem("first_step");
        this.router.navigate(["plan"]);
        if (response.body.message.description.includes("Agency")) {
          this.agencyPlan = true;
        } else {
          this.agencyPlan = false;
        }
      } else {
        if (response.body.message.description.includes("Agency")) {
          this.agencyPlan = true;
        } else {
          this.agencyPlan = false;
        }
      }
    });
  }
}
