import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "../../pages/common.service";
import { AuthService } from "../auth.service";
import { SocialAuthService } from "angularx-social-login";
import { BillingService } from "../../pages/billing/billing.service";
import { MixpanelService } from "../../shared/service/mixpanel.service";
import * as mixpanel from "mixpanel-browser";
import { AgencyService } from "../../pages/agency/agency.service";
declare let analytics: any;

@Component({
  selector: "App-signUp",
  templateUrl: "sign-up.component.html",
  styleUrls: ["sign-up.component.css"],
})
export class SignUpComponent implements OnInit {
  showSignpage: boolean = true;
  password = "password";
  signUpForm;
  button_spinner = false;
  // clientId ="427964124465-lee6i48l5pphhovmorqk318bbppehgmi.apps.googleusercontent.com";
  // clinetSec = "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";

   // clientId = '427964124465-lee6i48l5pphhovmorqk318bbppehgmi.apps.googleusercontent.com'
   clientId = '427964124465-lee6i48l5pphhovmorqk318bbppehgmi.apps.googleusercontent.com'
   // clinetSec = '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';
   clinetSec = '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';

  otpForm = new FormGroup({
    otp: new FormControl("", Validators.required),
  });

  token;
  firstPart;
  middlePart;
  lastPart;
  disableSubmit = false
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private common: CommonService,
    private authService: SocialAuthService,
    private billing: BillingService,
    private mixPanelservice:MixpanelService,
    private agency: AgencyService,
  ) {}

  ngOnInit(): void {
    this.getEmailParts("sshahid417@gmail.com", 1, 3, 4);
    window.scrollTo(0, 0);
    this.signUpForm = this.fb.group(
      {
        f_name: ["", Validators.required],
        l_name: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(6)]],
        // cnf_password: [''],
        referral_used: ["", Validators.maxLength(30)],
        checkbox: [false],
      }
      // {
      //     validator: ConfirmedValidator('password', 'cnf_password')
      // }
    );
  }

  getEmailParts(
    email: string,
    firstChars: number,
    middleChars: number,
    lastChars: number
  ) {
    const emailLength = email.length;

    if (firstChars + middleChars + lastChars > emailLength) {
      throw new Error("Total characters to extract exceed the email length.");
    }

    this.firstPart = email.slice(0, firstChars);
    this.middlePart = email.slice(firstChars, firstChars + middleChars);
    this.lastPart = email.slice(emailLength - lastChars, emailLength);
    // console.log(this.firstPart, this.middlePart, this.lastPart);
  }

  showOrhidePassword() {
    if (this.password === "password") {
      this.password = "text";
    } else {
      this.password = "password";
    }
  }

  checkBox(){
    // this.disableSubmit =  this.signUpForm.value.checkbox
    // console.log(this.signUpForm.value.checkbox,"value of checkbox")
  }

  // SignUp Submit

  onSubmit() {
    // this.spinner.show()
    this.button_spinner = true;
    // console.log(this.signUpForm.value)
    this.auth.signUp(this.signUpForm.value).subscribe((res) => {
      // this.spinner.hide();
      console.log(res, "signup res");
      this.button_spinner = false;
      if (res.status == 201) {
        // console.log(res, "res");
        analytics.identify(res.body.id, {
          email: res.body.email,
          name: res.body.name,
        });
        analytics.track('Completed sign up in auth page', {
          email:  this.signUpForm.value.email,
        });
        this.getEmailParts(this.signUpForm.value.email, 1, 3, 4);
       
        this.token = localStorage.getItem("token");
        if (this.token) {
          this.toastr.success("Verify Email address by entering OTP.", "", {
            closeButton: true,
            positionClass: "toast-bottom-center",
          });

          

          //   this.common.selectPreference("my network").subscribe((res) => {
          //     // console.log(res,"response");
          //   });
        }

        this.showSignpage = false;
      } else {
        // console.log(res, "error");
        for (let index = 0; index < res.error.email.length; index++) {
          this.toastr.error(res.error.email[index], "", {
            closeButton: false,
            positionClass: "toast-bottom-center",
          });
        }

        // this.router.navigate(['login'])
      }
    });
  }

  get f() {
    return this.signUpForm.controls;
  }
  // Otp verification

  onOTPsubmit() {
    this.button_spinner = true;
    this.auth.otpsubmission(this.otpForm.value.otp,this.signUpForm.value.email).subscribe((res) => {
      // console.log(res, "OTP submitted");
      localStorage.setItem("token", res.body.token);
      localStorage.setItem("referral_used", res.body.referral_used);
      // this.auth.sendEmail(this.signUpForm.value.email).subscribe((response) => {
      //   // console.log(response, "email send id");
      // });
      mixpanel.identify(res.body.id);
      this.mixPanelservice.track('Completed sign-up',{})
      this.mixPanelservice.track('Completed email verification',{})
      this.button_spinner = false;
      // analytics.identify(res.body.id, {
      //   email:  this.signUpForm.value.email,
      // });
      analytics.track('Verified email address', {
        email:  this.signUpForm.value.email,
      });
      // this.spinner.hide()
      if (res.status == 400) {
        this.toastr.error("Wrong or expired OTP!", "", {
          closeButton: false,
          positionClass: "toast-bottom-center",
        });
      } else {
        this.toastr.success("Registration complete!", "", {
          closeButton: false,
          positionClass: "toast-bottom-center",
        });
        localStorage.setItem("email", this.signUpForm.value.email);
        localStorage.setItem("isNewUser", res.body.is_new_user);
        localStorage.setItem('setIntervaltimeOut','true')
          this.toastr.success("Login successful. Welcome to Triggify!", "", {
            closeButton: true,
            positionClass: "toast-bottom-center",
          });
          this.billing.currentPlan().subscribe((response) => {
            // console.log(res,"current plan");
            if (response.body.message == "No subscription found") {
              this.agency.llinkedincredList().subscribe(res=>{
                if (res.body.length > 0) {
                  localStorage.setItem('linkedinId',res.body[0].id)
                  // this.common.linkedinId.next(res.body[0].id)
                }
              })
              localStorage.setItem("susbcribe", "False");
              this.router.navigate(["plan"]);
            } else {
              localStorage.setItem("sub_type", response.body.message.description);
  
              this.agency.llinkedincredList().subscribe((res) => {
                console.log(res, "response");
                if (res.body.length == 0) {
                  if (response.body.message.description.includes("Agency")) {
                    this.router.navigate(["agency-plan"]);
                    localStorage.setItem("agency", "True");
                  } else {
                    this.router.navigate(["plan"]);
                    localStorage.setItem("susbcribe", "False");
                    localStorage.setItem("headerHeading", "free");
                    // localStorage.setItem("sub_type", "free");
                    localStorage.setItem("first_step", "true");
                    localStorage.setItem("agency", "false");
                  }
                } else {
                  if (response.body.message.description.includes("Agency")) {
                    localStorage.setItem("agency", "True");
                  } else {
                    localStorage.setItem("agency", "false");
                  }
                  localStorage.setItem('linkedinId',res.body[0].id)
                  // this.common.linkedinId.next(res.body[0].id)
                  this.router.navigate(["dashboard/view_all"]);
                  localStorage.setItem("susbcribe", "True");
                }
              });
            }
          });
      }
    });
  }
  

  // Login With google

  signInwithGoogleAPI() {
    // return this.http.get<any>(
    //   `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&include_granted_scopes=true&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid&state=state_parameter_passthrough_value&redirect_uri=https://app.triggify.io/&client_id=427964124465-lee6i48l5pphhovmorqk318bbppehgmi.apps.googleusercontent.com`
    // );
    let url = "https://accounts.google.com/o/oauth2/v2/auth";
    let form = document.createElement("form");
    form.setAttribute("method", "GET");
    form.setAttribute("Action", url);

    let params = {
      response_type: "token",
      client_id: this.clientId,
      // redirect_uri: "https://app-dev.triggify.io/login",
      // redirect_uri: "http://localhost:4200/login",

      redirect_uri: "https://app.triggify.io/login",
      scope:
        "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
      state: "pass-through-value",
      include_granted_scopes: "true",
      prompt: "select_account",
    };

    for (var p in params) {
      let input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }
    document.body.appendChild(form);
    form.submit();
  }

  resendOTP(){
    this.auth
    .getOTPforverification(this.signUpForm.value.email)
    .subscribe((res) => {
      // console.log(res,"email otp send");
      this.toastr.success(res.body.message, "", {
        closeButton: true,
        positionClass: "toast-bottom-center",
      });
    });
  }
}
